import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { LoginCallback } from '@okta/okta-react';
import Loadable from '../layouts/loader/Loadable';
import AuthGuard from '../components/authGuard/AuthGuard';
import ApisList from '../views/apis-list/ApisList';
import OmniEmailsList from '../views/omni-emails/OmniEmailsList';

/****Layouts*****/
const FullLayout = Loadable(lazy(() => import('../layouts/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/BlankLayout')));
/***** Pages ****/

const B2BContactLookup = Loadable(
  lazy(() => import('../views/b2bcontact-lookup/B2BContactLookup')),
);
const GuestDashboard = Loadable(lazy(() => import('../views/guest-dashboard/GuestDashboard')));
const GuestDashboard2 = Loadable(lazy(() => import('../views/guest-dashboard2/GuestDashboard2')));
const GuestLookup = Loadable(lazy(() => import('../views/guest-lookup/GuestLookup')));
const RequestsLogger = Loadable(lazy(() => import('../views/requests-logger/RequestsLogger')));
const GroupStayRevenue = Loadable(
  lazy(() => import('../views/group-stay-revenue/GroupStayRevenue')),
);
const AliceApiKey = Loadable(lazy(() => import('../views/alice-api-key/AliceApiKey')));
const Logout = Loadable(lazy(() => import('../views/auth/Logout')));
const Merge = Loadable(lazy(() => import('../views/merge/Merge')));
const CRS = Loadable(lazy(() => import('../views/crs/CRS')));
const ProfileMerge = Loadable(lazy(() => import('../views/profile-merge/ProfileMerge')));
const ConfirmationLetter = Loadable(
  lazy(() => import('../views/confirmation-letter/ConfirmationLetter')),
);
const ProfileMergeList = Loadable(
  lazy(() => import('../views/profile-merge-list/ProfileMergeList')),
);
const Users = Loadable(lazy(() => import('../views/users/Users')));
const FeatureFlags = Loadable(lazy(() => import('../views/feature-flags/FeatureFlags')));
const ReviewPro = Loadable(lazy(() => import('../views/review-pro/ReviewPro')));
const AccountLookup = Loadable(lazy(() => import('../views/account-lookup/AccountLookup')));
const FeedbackList = Loadable(lazy(() => import('../views/feedback/List')));
const TalentConnect = Loadable(lazy(() => import('../views/talent-connect/TalentConnect')));
const BookingEngine = Loadable(lazy(() => import('../views/booking-engine/BookingEngine')));
const Silverware = Loadable(lazy(() => import('../views/silverware/Silverware')));
const Bella = Loadable(lazy(() => import('../views/bella/Bella')));
const UpdateOscId = Loadable(lazy(() => import('../views/update-osc-id/UpdateOscId')));
const Reservations = Loadable(lazy(() => import('../views/Reservations/Reservations')));
const Rooms = Loadable(lazy(() => import('../views/Rooms/Rooms')));
const Bookings = Loadable(lazy(() => import('../views/bookings/Bookings')));
const Products = Loadable(lazy(() => import('../views/Products/Products')));
const Discovery = Loadable(lazy(() => import('../views/Discovery/Discovery')));
const DiscoveryCodes = Loadable(lazy(() => import('../views/discovery-codes/DiscoveryCodes')));
const PayByLink = Loadable(lazy(() => import('../views/PayByLink/PayByLink')));
const RoomCategories = Loadable(lazy(() => import('../views/room-categories/RoomCategories')));
const CorrespondenceLetters = Loadable(
  lazy(() => import('../views/correspondence-letters/CorrespondenceLetters')),
);
const OperaDataMigration = Loadable(
  lazy(() => import('../views/opera-data-migration/OperaDataMigration')),
);
const KitchenCut = Loadable(lazy(() => import('../views/kitchen-cut/KitchenCut')));
const ClientelBookings = Loadable(
  lazy(() => import('../views/clientel-bookings/ClientelBookings')),
);
const RequestLoggerErrors = Loadable(
  lazy(() => import('../views/RequestLoggerErrors/RequestLoggerErrors')),
);
const SynxisHotels = Loadable(lazy(() => import('../views/synxis-hotels/SynxisHotels')));
const SynxisRooms = Loadable(lazy(() => import('../views/synxis-rooms/SynxisRooms')));
const SynxisRates = Loadable(lazy(() => import('../views/synxis-rates/SynxisRates')));

const SynxisFilters = Loadable(lazy(() => import('../views/synxis-filters/SynxisFilters')));
const CancellationPolicies = Loadable(
  lazy(() => import('../views/cancellation-policies/CancellationPolicies')),
);

const SourceOfBusinessList = Loadable(
  lazy(() => import('../views/source-of-business/SourceOfBusinessList')),
);

const BBEMaintenanceScheduler = Loadable(
  lazy(() => import('../views/bbe-maintenance-scheduler/BBEMaintenanceScheduler')),
);
const BiReports = Loadable(lazy(() => import('../views/bi-reports/BiReports')));

const Addons = Loadable(lazy(() => import('../views/addons/Addons')));
const MealPlans = Loadable(lazy(() => import('../views/meal-plans/MealPlans')));
const Unifocus = Loadable(lazy(() => import('../views/unifocus/Unifocus')));
const SevenRooms = Loadable(lazy(() => import('../views/seven-rooms/SevenRooms')));
const APIDocsViewer = Loadable(lazy(() => import('../views/api-docs-viewer/APIDocsViewer')));
const Maintenance = Loadable(lazy(() => import('../views/Maintenance/Maintenance')));

/***** Auth Pages ****/
const Error = Loadable(lazy(() => import('../views/auth/Error')));
/*****Routes******/

const ThemeRoutes = [
  { path: '/login/callback', element: <LoginCallback /> },
  {
    path: '/',
    element: (
      <AuthGuard>
        <FullLayout />
      </AuthGuard>
    ),
    children: [
      // { path: '/', element: <Navigate to="/requests-logger" /> },
      { path: '/', element: <BlankLayout /> },
      { path: '/reviewpro', exact: true, element: <ReviewPro /> },
      { path: '/account-lookup', exact: true, element: <AccountLookup /> },
      { path: '/b2bcontact-lookup', exact: true, element: <B2BContactLookup /> },
      { path: '/guest-dashboard', exact: true, element: <GuestDashboard /> },
      { path: '/guest-dashboard2', exact: true, element: <GuestDashboard2 /> },
      { path: '/360', exact: true, element: <GuestLookup /> },
      { path: '/bella', exact: true, element: <Bella /> },
      { path: '/update-osc-id', exact: true, element: <UpdateOscId /> },
      { path: '/bookings', exact: true, element: <Bookings /> },
      { path: '/reservations', exact: true, element: <Reservations /> },
      { path: '/rooms', exact: true, element: <Rooms /> },
      { path: '/products', exact: true, element: <Products /> },
      { path: '/merge', exact: true, element: <Merge /> },
      { path: '/booking-engine', exact: true, element: <BookingEngine /> },
      { path: '/silverware', exact: true, element: <Silverware /> },
      { path: '/talent-connect', exact: true, element: <TalentConnect /> },
      { path: '/o2i', exact: true, element: <OperaDataMigration /> },
      { path: '/correspondence-letters', exact: true, element: <CorrespondenceLetters /> },
      { path: '/apis', exact: true, element: <ApisList /> },
      { path: '/crs', exact: true, element: <CRS /> },
      { path: '/confirmation-letter', exact: true, element: <ConfirmationLetter /> },
      { path: '/profile-merge', exact: true, element: <ProfileMerge /> },
      { path: '/feedback', exact: true, element: <FeedbackList /> },
      { path: '/profile-merge-list', exact: true, element: <ProfileMergeList /> },
      { path: '/alice-api-key', exact: true, element: <AliceApiKey /> },
      { path: '/kitchen-cut', exact: true, element: <KitchenCut /> },
      { path: '/group-stay-revenue', exact: true, element: <GroupStayRevenue /> },
      { path: '/users', exact: true, element: <Users /> },
      { path: '/feature-flags', exact: true, element: <FeatureFlags /> },
      { path: '/requests-logger', exact: true, element: <RequestsLogger /> },
      { path: '/omni-emails', exact: true, element: <OmniEmailsList /> },
      { path: '/clientel-bookings', exact: true, element: <ClientelBookings /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
      { path: '/request-logger-errors', exact: true, element: <RequestLoggerErrors /> },
      { path: 'discovery', exact: true, element: <Discovery /> },
      { path: '/pay-by-link', exact: true, element: <PayByLink /> },
      { path: 'room-categories', exact: true, element: <RoomCategories /> },
      { path: '/unifocus', exact: true, element: <Unifocus /> },
      { path: '/api-docs', exact: true, element: <APIDocsViewer /> },
      { path: '/synxis-hotels', exact: true, element: <SynxisHotels /> },
      { path: '/synxis-rooms', exact: true, element: <SynxisRooms /> },
      { path: '/synxis-rates', exact: true, element: <SynxisRates /> },
      { path: '/source-of-business', exact: true, element: <SourceOfBusinessList /> },
      { path: '/source-of-business2', exact: true, element: <SourceOfBusinessList secondary /> },
      { path: '/addons', exact: true, element: <Addons /> },
      { path: '/synxis-filters', exact: true, element: <SynxisFilters /> },
      { path: '/discovery-codes', exact: true, element: <DiscoveryCodes /> },
      { path: '/cancellation-policies', exact: true, element: <CancellationPolicies /> },
      { path: '/meal-plans', exact: true, element: <MealPlans /> },
      { path: '/seven-rooms/venues', exact: true, element: <SevenRooms /> },
      { path: '/maintenance', exact: true, element: <Maintenance /> },
      { path: '/bbe-maintenance-scheduler', exact: true, element: <BBEMaintenanceScheduler /> },
      { path: '/bi-reports', exact: true, element: <BiReports /> },
    ],
  },
  {
    path: '/auth',
    element: <BlankLayout />,
    children: [
      { path: '404', element: <Error /> },
      { path: 'logout', element: <Logout /> },
      { path: '*', element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default ThemeRoutes;
